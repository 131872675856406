
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.wrapper {
    padding: 50px 0;
    background-color: #f1f1f1;
    h2 {
        @apply text-xl mb-4 font-bold;
        @include md {
            @apply text-2xl;
        }
    }
    p {
        color: $bestearpluglawyers-grey;
        font-weight: 400;
        font-size: 15px;
        line-height: 32px;
        text-align: left;
        @include md {
            font-size: 18px;
        }
    }
}
